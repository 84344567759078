<template>
  <form class="form-wrapper" ref="form" :autocomplete="autocomplete">
    <slot />
  </form>
</template>

<script>
export default {
  name: "FormWrapper",

  data: () => ({
    inputs: [],
    currIndex: 0
  }),

  props: {
    autocomplete: String
  },

  mounted() {
    const _form = this.$refs.form

    this.inputs = Array.from(_form.querySelectorAll("input, textarea"))

    this.inputs.forEach(
      input =>
        (input.onfocus = evt => {
          this.currIndex = this.inputs.indexOf(evt.target)
        })
    )

    _form.onkeydown = event => {
      // Hitting 'Enter' should let user skip to next input (unless they're in a textarea)
      if (event.key === "Enter" && event.target !== "TEXTAREA") {
        event.preventDefault()
        if (this.currIndex < this.inputs.length - 1) {
          this.inputs[this.currIndex + 1].focus()
        }
      }
    }
  }
}
</script>

<style></style>
